import {storeVersion} from 'constants/config.js';

const INITIAL_STATE = {
  storeVersion: storeVersion,
};


export default function reducer(state = INITIAL_STATE) {
  return state
}
