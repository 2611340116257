import { applyMiddleware, compose, createStore } from 'redux';
// middlewares
import thunkMiddleware from 'redux-thunk';
// Import custom components
import rootReducer from 'reducers';
import {storeVersion} from 'constants/config.js';



function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
    localStorage.setItem('storeVersion', state.StoreVersion.storeVersion);
  } catch (e) {
    // console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    const localStoreVersion = localStorage.getItem('storeVersion');
    if (serializedState === null || localStoreVersion === null || localStoreVersion !== storeVersion) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    // console.log(e)
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();

/**
 * Create a Redux store that holds the app state.
 */
const store = createStore(rootReducer, persistedState, compose(
  applyMiddleware(thunkMiddleware),
  // For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
  // eslint-disable-next-line
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) {
    return f;
  },
));

store.subscribe(() => {
  const state = store.getState();
  saveToLocalStorage(state);
});

export default store;
