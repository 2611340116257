import React, {Component} from 'react';
import loadable from '@loadable/component';
import {IntlProvider} from 'react-redux-multilingual';

import {
  HashRouter as Router, Route, Switch,
} from 'react-router-dom';

import {Provider} from 'react-redux';

import store from 'store/store';
import translations from 'constants/translations/index';

import Loading from 'components/common/Loading/Loading.jsx';

import AOS from 'aos';

import 'scss/index.scss';
import Header from "./components/common/Header/Header";
import Footer from "./components/common/Footer/Footer";

const MainPage = loadable(() => import('components/pages/MainPage/MainPage.jsx'));

class App extends Component {
  constructor(props) {
    console.log('App created');
    super(props);
  }

  componentDidMount() {
    console.log('App mounted');
  }

  render() {
    AOS.init({
      duration : 600
    });

    return (

       <Provider store={store}>
          <IntlProvider translations={translations} locale='en'>
            <Header/>
            <Router>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(routeProps) =>
                    <MainPage {...routeProps}
                              fallback={<Loading/>}
                    />
                  }
                />
              </Switch>
            </Router>
            <Footer/>
          </IntlProvider>
        </Provider>


    );
  }
}

export default App;