import dummy from './dummy';

const translation = {
  en: {
    locale: 'en-US',
    messages: {
      test: 'Test',
      dummy: dummy.en,
    },
  },
  ru: {
    locale: 'ru-RU',
    messages: {
      test: 'Тест',
      dummy: dummy.ru || dummy.en,
    },
  },
};

export default translation;