import React from 'react';

function Loading() {
    return (
        <div id="loader-wrapper">
            <div id="loader">
                <div className="loader-inner"/>
            </div>
        </div>
    );
}


export default Loading;